import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/pages/runtime/validate.js";
import middleware_45global from "/vercel/path0/middleware/middleware.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "index-middleware": () => import("/vercel/path0/middleware/indexMiddleware.ts"),
  partner: () => import("/vercel/path0/middleware/partner.ts"),
  payment: () => import("/vercel/path0/middleware/payment.ts"),
  registration: () => import("/vercel/path0/middleware/registration.ts")
}