import revive_payload_client_YWTP8D4f4U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TFvVQC1M8A from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aVGo0C6Ajl from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_p75SVXC3iw from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NjIYjWA7xo from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XtRmi1l9dd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RLfDbS0kpA from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oDDFUlhXff from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_awWlBvoucl from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.8.0_ioredis@5.6.0_m_gbiiop52pihmuzfwdxcsw5wory/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_vqzXnXC8On from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_1tpbvngyLC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_cFjYLYXf2c from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.5.4_vue_e3qzkemkes3bhkp77wlzo5nlz4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import floating_vue_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import router_options_Vfa2sdOsAH from "/vercel/path0/plugins/router.options.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vue_cookie_comply_with_reject_client_vBi4yoXDDv from "/vercel/path0/plugins/vue-cookie-comply-with-reject.client.ts";
export default [
  revive_payload_client_YWTP8D4f4U,
  unhead_TFvVQC1M8A,
  router_aVGo0C6Ajl,
  payload_client_p75SVXC3iw,
  navigation_repaint_client_NjIYjWA7xo,
  check_outdated_build_client_XtRmi1l9dd,
  chunk_reload_client_RLfDbS0kpA,
  plugin_vue3_oDDFUlhXff,
  components_plugin_KR1HBZs4kY,
  prefetch_client_awWlBvoucl,
  plugin_6Ph1iQzNHk,
  switch_locale_path_ssr_vqzXnXC8On,
  i18n_1tpbvngyLC,
  plugin_cFjYLYXf2c,
  floating_vue_BiOD74u9sH,
  router_options_Vfa2sdOsAH,
  sentry_client_shVUlIjFLk,
  vue_cookie_comply_with_reject_client_vBi4yoXDDv
]